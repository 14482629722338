<template>
  <vx-card title="Payment for DN Reversal">
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <vs-button
          v-on:click="submit()"
          color="primary"
          icon-pack="feather"
          icon="icon-inbox"
          >Submit</vs-button
        >
        <br />
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th width="5%">
              Action
            </th>
            <vs-th>
              <vs-checkbox
                v-on:click="approveAll()"
                v-model="statusApproveAll"
                color="primary"
                >Approve All</vs-checkbox
              >
            </vs-th>
            <vs-th>
              <vs-checkbox
                v-on:click="rejectAll()"
                v-model="statusRejectAll"
                color="warning"
                >Reject All</vs-checkbox
              >
            </vs-th>
          </template>
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.otherPayment">
              <vs-td class="whitespace-no-wrap">
                 <div class="mt-4 flex mr-1">
                  <vx-tooltip text="View Supplier Payment" class="mr-4">
                    <vs-button
                      color="warning"
                      type="line"
                      icon-pack="feather"
                      @click="handleView(tr)"
                      icon="icon-eye"
                    />
                  </vx-tooltip>
                  
                 </div>
              </vs-td>
              <vs-td>
                <vs-checkbox
                  v-on:click="
                    approve(
                      indextr,
                      tr.ID
                    )
                  "
                  :checked="payment_id_approve.includes(tr.ID)"
                  color="primary"
                ></vs-checkbox>
              </vs-td>
              <vs-td>
                <vs-checkbox
                  v-on:click="
                    reject(
                      indextr,
                      tr.ID
                    )
                  "
                  :checked="payment_id_reject.includes(tr.ID)"
                  color="warning"
                ></vs-checkbox>
              </vs-td>
              <vs-td>
                {{ tr.PartnerCode }} {{ tr.PartnerName }} 
              </vs-td>
              <vs-td>
                Code : {{tr.Code}}
                <br>
                Reference Code : {{tr.ReferenceCode}}
                <br>
                Payment Type : {{typePayment[tr.PaymentMethod]}}
                <br>
                {{ tr.BankName }} {{ tr.BankAccountNumber }} ({{ tr.BankAccountName }})
              </vs-td>
              <vs-td>
                {{ priceFormat(tr.Amount) }}
              </vs-td>
              <vs-td>
                Date : {{ dateFormat(tr.DatePayment) }}
                Reverse Date : {{ dateFormat(tr.ReverseDate) }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="changeStatus"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="confirmPrompt"
      >
        <div class="con-exemple-prompt">
          Supplier Payment
          <br />
          Are you sure to confirm <b>{{this.selectedData.Code}}</b> supplier payment ?
          <br />
          <br />
          <!-- <vs-textarea v-model="notes"/> -->
        </div>
      </vs-prompt>
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="changeStatus"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="cancelPrompt"
      >
        <div class="con-exemple-prompt">
          Supplier Payment
          <br />
          Are you sure to cancel <b>{{this.selectedData.Code}}</b> supplier payment ?
          <br />
          <br />
          <!-- <vs-textarea v-model="notes"/> -->
        </div>
      </vs-prompt>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
              <form-view :selected="selectedData" @closeDetail="closeDetail" />
           
          </div>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import form_view from "./form-view.vue";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
  },
  components: {
    "form-view":form_view
  },
  data() {
    return {
      typePayment: ["", "Giro", "Cheque", "Transfer"],
      confirmPrompt: false,
      cancelPrompt:false,
      status:0,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
      },
      header: [
        {
          text: "Partner",
          value: "partner_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      statusApproveAll: false,
      statusRejectAll: false,
      payment_id_approve: [],
      payment_id_reject: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // this.reloadData(this.params);

    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    create() {
      this.selectedData = {ID:0}
      this.detail = true;
    },
    handleView(selected) {
      this.selectedData = selected
      this.detail = true;
    },
    edit(selected) {
      this.selectedData = selected
      this.detail = true;
    },
    promptConfirm (data) {
      this.confirmPrompt = true
      this.status = 1
      this.cancelPrompt = false
      this.selectedData = data
      console.log("confirm")
    },
    promptCancel (data) {
      this.confirmPrompt = false
      this.status = 3
      this.cancelPrompt = true
      this.selectedData = data
      console.log("confirm")
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    closeDetail(params = false) {
      console.log(params)
      window.scrollTo(0, 0);
      this.detail = false;
      this.confirmPrompt = false
      this.status = 2
      this.cancelPrompt = false
      if (params == true || this.selectedData.ID != 0) {
        this.reloadData(this.params);
      }
      this.selectedData = {
        ID: 0,
      };
    },
    approveAll() {
      this.statusRejectAll = false;
      let _this = this;
      this.data.forEach(function (element) {
        element.status_checked_reject = false;
        element.status_checked_approve = true;

        if (!_this.payment_id_approve.includes(element.ID)) {
          _this.payment_id_approve.push(element.ID);
        } else {
          var index2 = _this.payment_id_approve.indexOf(element.ID);
          if (index2 !== -1) {
            _this.payment_id_approve.splice(index2, 1);
          }
        }
      });
      this.payment_id_reject = [];
      this.purchase_return_id = [];
      console.log(this.purchase_return_id);

      // console.log(this.payment_id_approve);
      // console.log(this.payment_id_reject);
    },
    rejectAll() {
      this.statusApproveAll = false;
      let _this = this;
      this.data.forEach(function (element) {
        element.status_checked_reject = true;
        element.status_checked_approve = false;

        if (!_this.payment_id_reject.includes(element.ID)) {
          _this.payment_id_reject.push(element.ID);
          _this.purchase_return_id.push(element.purchase_return_id);
        } else {
          var index2 = _this.payment_id_reject.indexOf(element.ID);
          if (index2 !== -1) {
            _this.payment_id_reject.splice(index2, 1);
          }

          var index3 = _this.purchase_return_id.indexOf(
            element.purchase_return_id
          );
          if (index3 !== -1) {
            _this.purchase_return_id.splice(index3, 1);
          }
        }
      });
      this.payment_id_approve = [];
      console.log(this.purchase_return_id);

      // console.log(this.payment_id_approve);
      // console.log(this.payment_id_reject);
    },
    approve(index, payment_id) {
      console.log("approve");
      if (!this.payment_id_approve.includes(payment_id)) {
        this.payment_id_approve.push(payment_id);
      } else {
        var index2 = this.payment_id_approve.indexOf(payment_id);
        if (index2 !== -1) {
          this.payment_id_approve.splice(index2, 1);
        }
      }
      var index1 = this.payment_id_reject.indexOf(payment_id);
      if (index1 !== -1) {
        this.payment_id_reject.splice(index1, 1);
      }

      // console.log(this.payment_id_approve);
      // console.log(this.payment_id_reject);
    },
    reject(index, payment_id) {
      console.log("reject");
      if (!this.payment_id_reject.includes(payment_id)) {
        this.payment_id_reject.push(payment_id);
      } else {
        var index2 = this.payment_id_reject.indexOf(payment_id);
        if (index2 !== -1) {
          this.payment_id_reject.splice(index2, 1);
        }
      }

      var index1 = this.payment_id_approve.indexOf(payment_id);
      if (index1 !== -1) {
        this.payment_id_approve.splice(index1, 1);
      }

      // console.log(this.payment_id_approve);
      // console.log(this.payment_id_reject);
    },
    submit() {
      if (this.payment_id_approve.length == 0 && this.payment_id_reject.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Select data first.",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.loading();
        let param = {
          payment_id_approve: this.payment_id_approve,
          payment_id_reject: this.payment_id_reject,
          purchase_return_id: this.purchase_return_id,
        };
        this.$http
          .post("/api/v1/other-payment/approve-reject/reversal", param)
          .then((resp) => {
            this.$vs.loading.close();
            this.closeDetail()
            if (resp.code == 200) {
              this.payment_id_approve = [];
              this.payment_id_reject = [];
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    changeStatus () {
      let params = {
        id: this.selectedData.ID,
        status: this.status
      }
      this.$vs.loading;
      this.$http.post('/api/v1/other-payment/for-dn/change-status',params).then((result) => {
        this.$vs.loading.close();
          if (result.code == 200) {
            this.closeDetail()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.closeDetail()
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch((e) => {
          console.log(e)
        
      })
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http.get('/api/v1/other-payment/for-dn/data-table',{
            params: {
                status: 6,
                type:1,
                search: params.search,
                length: params.length,
                page: params.page,
                order: params.order,
                sort: params.sort,
            }
        }).then((r) => {
          resolve(r)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      // this.$vs.loading();
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.otherPayment;
          this.responseData = r.data;
          this.responseData.length = r.data.otherPayment.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
     
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>