var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Payment for DN Reversal"}},[_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('vs-button',{attrs:{"color":"primary","icon-pack":"feather","icon":"icon-inbox"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Submit")]),_c('br'),_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"},[_c('th',{attrs:{"width":"5%"}},[_vm._v(" Action ")]),_c('vs-th',[_c('vs-checkbox',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.approveAll()}},model:{value:(_vm.statusApproveAll),callback:function ($$v) {_vm.statusApproveAll=$$v},expression:"statusApproveAll"}},[_vm._v("Approve All")])],1),_c('vs-th',[_c('vs-checkbox',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.rejectAll()}},model:{value:(_vm.statusRejectAll),callback:function ($$v) {_vm.statusRejectAll=$$v},expression:"statusRejectAll"}},[_vm._v("Reject All")])],1)],1),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.otherPayment),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{staticClass:"whitespace-no-wrap"},[_c('div',{staticClass:"mt-4 flex mr-1"},[_c('vx-tooltip',{staticClass:"mr-4",attrs:{"text":"View Supplier Payment"}},[_c('vs-button',{attrs:{"color":"warning","type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){return _vm.handleView(tr)}}})],1)],1)]),_c('vs-td',[_c('vs-checkbox',{attrs:{"checked":_vm.payment_id_approve.includes(tr.ID),"color":"primary"},on:{"click":function($event){return _vm.approve(
                    indextr,
                    tr.ID
                  )}}})],1),_c('vs-td',[_c('vs-checkbox',{attrs:{"checked":_vm.payment_id_reject.includes(tr.ID),"color":"warning"},on:{"click":function($event){return _vm.reject(
                    indextr,
                    tr.ID
                  )}}})],1),_c('vs-td',[_vm._v(" "+_vm._s(tr.PartnerCode)+" "+_vm._s(tr.PartnerName)+" ")]),_c('vs-td',[_vm._v(" Code : "+_vm._s(tr.Code)+" "),_c('br'),_vm._v(" Reference Code : "+_vm._s(tr.ReferenceCode)+" "),_c('br'),_vm._v(" Payment Type : "+_vm._s(_vm.typePayment[tr.PaymentMethod])+" "),_c('br'),_vm._v(" "+_vm._s(tr.BankName)+" "+_vm._s(tr.BankAccountNumber)+" ("+_vm._s(tr.BankAccountName)+") ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.priceFormat(tr.Amount))+" ")]),_c('vs-td',[_vm._v(" Date : "+_vm._s(_vm.dateFormat(tr.DatePayment))+" Reverse Date : "+_vm._s(_vm.dateFormat(tr.ReverseDate))+" ")])],1)}),1)],2)],1),_c('vs-prompt',{attrs:{"title":"Confirmation","color":"primary","buttons-hidden":false,"active":_vm.confirmPrompt},on:{"accept":_vm.changeStatus,"cancel":_vm.closeDetail,"close":_vm.closeDetail,"update:active":function($event){_vm.confirmPrompt=$event}}},[_c('div',{staticClass:"con-exemple-prompt"},[_vm._v(" Supplier Payment "),_c('br'),_vm._v(" Are you sure to confirm "),_c('b',[_vm._v(_vm._s(this.selectedData.Code))]),_vm._v(" supplier payment ? "),_c('br'),_c('br')])]),_c('vs-prompt',{attrs:{"title":"Confirmation","color":"primary","buttons-hidden":false,"active":_vm.cancelPrompt},on:{"accept":_vm.changeStatus,"cancel":_vm.closeDetail,"close":_vm.closeDetail,"update:active":function($event){_vm.cancelPrompt=$event}}},[_c('div',{staticClass:"con-exemple-prompt"},[_vm._v(" Supplier Payment "),_c('br'),_vm._v(" Are you sure to cancel "),_c('b',[_vm._v(_vm._s(this.selectedData.Code))]),_vm._v(" supplier payment ? "),_c('br'),_c('br')])]),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),_c('form-view',{attrs:{"selected":_vm.selectedData},on:{"closeDetail":_vm.closeDetail}})],1)]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }